@import url("fonts.css");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Base **/

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 20px;
}

body {
	margin: 0;
	--color-text: #ffffff;
	--color-text-alt: #c7e9f041;
	--color-bg: rgb(0, 0, 0);
	--color-link: #F2C61F;
	--color-link-hover: #f8ecde;
	--color-title: #FAFAFA;
	--color-header: #C7E9F0;
	--color-box-outline: #FCD8CA;
	--color-section: #FAFAFA;
	color: var(--color-text);
	background-color: var(--color-bg);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: berlinsans, sans-serif;
	--font-family-title: berlinsans, sans-serif;
	--font-family-text: roboto, sans-serif;
	--font-family-chinese: qingke;
	--font-size: 20px;
	max-width: 100%;
    overflow-x: hidden;
}

/* Grainy texture animation by Geoff Graham https://css-tricks.com/snippets/css/animated-grainy-texture/ */

main::before {
	/* animation: grain 8s steps(10) infinite; */
	animation: grain 20s steps(1) infinite;
	/* background-image: url(../images/noise.png); */
	background-image: url(https://awv3node-homepage.surge.sh/build/assets/stars.svg);
	content: '';
	height: 300%;
	left: -50%;
	opacity: 0.6;
	position: fixed;
	top: -100%;
	width: 300%;
}

@keyframes grain {
  0%, 100% { transform:translate(0, 0); }
  10% { transform:translate(-5%, -10%); }
  20% { transform:translate(-15%, 5%); }
  30% { transform:translate(7%, -25%); }
  40% { transform:translate(-5%, 25%); }
  50% { transform:translate(-15%, 10%); }
  60% { transform:translate(15%, 0%); }
  70% { transform:translate(0%, 15%); }
  80% { transform:translate(3%, 35%); }
  90% { transform:translate(-10%, 10%); }
}

h1, h2, h3 {
	color: var(--color-header);
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover,
a:focus {
	color: var(--color-link-hover);
	outline: none;
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
	line-height: 1;
}

.frame__title {
	font-size: 4rem;
	margin: 0 0 1rem;
	font-weight: 700;
}

/* .frame__links {
	display: inline;
} */

.frame__links a:not(:last-child) {
	margin-right: 1rem;
}


.menu {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 10vh 0 25vh;
	--marquee-width: 100vw;
	--offset: 20vw;
	--move-initial: calc(-25% + var(--offset));
	--move-final: calc(-50% + var(--offset));
	--item-font-size: 8vw;
	counter-reset: menu;
	text-align: left;
}

.menu__item {
	cursor: default;
	position: relative;
	padding: 0 5vw;
}

.menu__item-link {
	display: inline-block;
	cursor: pointer;
	position: relative;
	-webkit-text-stroke: 1.5px var(--color-title);
	text-stroke: 1.5px var(--color-title);
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
	transition: opacity 0.4s;
}

.menu__item-link::before {
	all: initial;
	counter-increment: menu;
	color: var(--color-title);
	content:  counter(menu);
	position: absolute;
	bottom: 60%;
	left: 0;
	pointer-events: none;
	font-family: var(--font-family-chinese);
}

.menu__item-link:hover {
	transition-duration: 0.1s;
	opacity: 0;
}

.menu__item-img {
	pointer-events: none;
	position: absolute;
	height: 50vh;
	max-height: 400px;
	opacity: 0;
	left: 100%;
	top: 50%;
	transform: translate3d(calc(-100% - 6vw),-30%,0) translate3d(0,20px,0);
}

.menu__item-link:hover + .menu__item-img {
	opacity: 1;
	transform: translate3d(calc(-100% - 6vw),-30%,0) rotate3d(0,0,1,4deg);
	transition: all 0.4s;
}

/* Make sure 3 items are visible in the viewport by setting suitable font size. */

.marquee {
	position: absolute;
	top: 0;
	left: 0;
	width: var(--marquee-width);
	overflow: hidden;
	pointer-events: none;
	mix-blend-mode: color-burn;
}

.marquee__inner {
	width: fit-content;
	display: flex;
	position: relative;
	transform: translate3d(var(--move-initial), 0, 0);
	animation: marquee 5s linear infinite;
	animation-play-state: paused;
	opacity: 0;
	transition: opacity 0.1s;
}

.menu__item-link:hover ~ .marquee .marquee__inner {
	animation-play-state: running;
	opacity: 1;
	transition-duration: 0.4s;
}

.marquee span {
	text-align: center;
}

.menu__item-link,
.marquee span {
	white-space: nowrap;
	font-size: var(--item-font-size);
	padding: 0 1vw;
	font-weight: 900;
	line-height: 1.15;
	font-family: var(--font-family-chinese);
	text-transform: uppercase;
	letter-spacing: 16px;
}

.marquee span {
	font-style: italic;
}

@keyframes marquee {
	0% {
		transform: translate3d(var(--move-initial), 0, 0);
	}

	100% {
		transform: translate3d(var(--move-final), 0, 0);
	}
}
@media screen and (max-width: 768px) {
	.menu {
		display: none;
	}
}
@media screen and (min-width: 53em) {

	.frame {
		text-align: center;
		display: grid;
		align-content: space-between;
		width: 100%;
		padding: 2.5rem 6vw;
		pointer-events: none;
		grid-template-columns: 75% 25%;
		grid-template-rows: auto;
	}

	.frame__title-wrap {
		display: flex;
		/* align-items: start; */
	}

	.frame__info {
		margin: 0;
		/* font-style: italic; */
		font-family: var(--font-family-chinese);
	}

	.frame__title {
		margin: 0 1rem 0 0;
		font-family: var(--font-family-text);
	}

	.frame__links {
		padding: 0;
		justify-self: end;
		font-family: var(--font-family-text);
	}

	.frame a {
		pointer-events: auto;
	}
}


/* Cursor */
@media (any-pointer: fine) {
	.cursor {
		display: block;
	}
	.cursor__inner {
		z-index: 9999;
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		mix-blend-mode: difference;
		border-radius: 50%;
	}
	.cursor__side {
		position: absolute;
		top: 50%;
		width: 5px;
		height: 1px;
		background: #fff;
		opacity: 0;
	}
	.cursor__side--left {
		right: calc(100% + 5px);
	}
	.cursor__side--right {
		left: calc(100% + 5px);
	}
	.cursor__inner--circle {
		width: 25px;
		height: 25px;
		border: 1px solid #fff;
	}
}
