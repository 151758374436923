

/*
 * Berlin Sans FB Demi Bold
================================================================================ */   
@font-face {
    font-family: 'berlinsans';
    src: url('fonts/berlinsans/BerlinSansFBDemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*
 * Chinese Fonts 
================================================================================ */   
@font-face {
    font-family: 'qingke';
    src: url('fonts/qingke/ZCOOLQingKeHuangYou-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*
 * PT Sans
================================================================================ */   
@font-face {
    font-family: 'pt-sans';
    src: url('fonts/pt-sans/PTSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*
 * Roboto
================================================================================ */   
@font-face {
    font-family: 'roboto';
    src: url('fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}